import React, { useEffect } from "react"
import "./style.scss"
import Navbar from "../navbar"
import arungovilpic from "../../images/team/ArunGovil.png"
import hbalagopalpic from "../../images/team/HBalagopal.png"
import richapic from "../../images/team/Richa.png"
import Layout from "../layout"
const team = [
  { name: "Arun Govil", pic: arungovilpic, role: "Editor" },
  { name: "H. Balagopal", pic: hbalagopalpic, role: "Editor" },
  { name: "Richa Aniruddha", pic: richapic, role: "Editor-in-Chief" },
]

export default function Team() {
  useEffect(() => {
    window.location = "/"
  }, [])
  const teamJSX = team.map((member, i) => (
    <div className="col col-12 col-md-6 col-lg-4">
      <div className="team__card">
        <div className="team__card__name">{member.name}</div>
        <div className="team__card__role">{member.role}</div>
        <img className="team__card__img" src={member.pic} />
      </div>
    </div>
  ))
  return null
  return (
    <Layout>
      <div className="team">
        <Navbar />
        <hr className="hr" />
        <h1 className="heading-primary">Our Team</h1>
        <div className="row team__row">{teamJSX}</div>
      </div>
    </Layout>
  )
}
